@import '../../assets/scss/variables';

// **** Terms and Conditions Page CSS **** //
.tc-block{
    font-size: 20px;
    line-height: 32px;
    .tb-style{
        padding: 1rem 0rem;
        td,th{
            color: $white;
            border-bottom: 1px solid rgba(234, 236, 240, 0.1);
        }
        td{
            background: #0E0020;
        }
        th{
            background-color: #1F122F;
        }
        ul{
            padding-left: 40px !important;
            li{
                font-size: 18px;
            }
        }
        .p-datatable-wrapper{
            border-radius: 14px;
            border: 1px solid #332447;
        }
    }
    .terms-and-conditions-section{
        padding: 2rem 2rem;
        width: 70%;
        max-width: 1280px;
        margin: 0 auto;
        .custom-li {
            list-style-type: disc; /* Ensures dots are used as bullets */
            display: list-item; /* Makes sure the list item is displayed correctly */
            margin-left: 20px; /* Optional: Adjusts the margin to match the typical list item indentation */
            color:$white
        }
        .email-link {
            color: $primary;
            text-decoration: none;
          }
        .flex {
            display: flex;
            align-items: center;
        }
        
        .sub-number-ol {
            list-style: none; /* Removes default list styling */
            padding-left: 5px; /* Removes default padding */
            p{
                font-weight: 600;
                font-size: 18px;
            }
        }
                .sub-number-ol-two {
                    .custom-li {
                        .flex {
                            p {
                                font-weight: 600;
                                font-size: 18px;
                            }
                            .sublist-p{
                                font-size: 18px;
                                font-weight: 400;
                            }
        
                        }
        
                        span {
                            font-size: 18px;
                        }
                    }

        
                    list-style: none;
                    /* Removes default list styling */
                    padding-left: 30px;
                    /* Removes default padding */
        
                }
        .provider{
            color:$primary;
            text-decoration: underline;
            cursor: pointer;
        }
        .subHeading{
            font-size: 22px;
            line-height: 32px;
            color: $white;
            margin: 10px 0px 10px;
            margin-top: 50px;
            font-weight: 600;
        }
        h3{
            font-size: 18px;
            line-height: 30px;
            color: $white;
            margin: 10px 0px 10px;
            font-weight: 600;
        }
        p{
            font-size: 20px;
            line-height: 32px;
            color: $white;
            margin: 10px 0px 10px;
            a{
                color: $primary;
                text-decoration: underline;
            }
        }
        .terms-section{
            h2{
                font-size: 22px;
                font-weight: 600;
                color: $white;
                font-family: $font-base;
                margin-bottom: 1rem;
                margin-top: 2rem;
                span{
                    border-radius: 5px;
                    color: $white;
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 500;
                    height: 30px;
                    line-height: 30px;
                    margin-right: 8px;
                    text-align: center;
                    width: 30px;
                }
            }
        }
        .page{
            color:$primary;
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            line-height: 160%;
        }
        h1{
            margin: 0px;
            color:$white;
            font-size: 52px;
            font-weight: 500;
            line-height: 160%;
            text-align: center;
        }
       
        ul{
            margin: 0px;
            padding: 5px 0px;
            counter-reset: item;
            
            &.sub-number-ol{
                counter-reset: item;
                list-style-type: none;
                margin: 0px;
                padding: 5px 0px;
                li{
                    counter-increment: item;
                    margin-bottom: 18px;
                    //font-family: $font-secondary;
                    padding-left: 5px;
                    font-size: 18px;
                    span{
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 30px;
                        color: $white;
                        &.numbers{
                           color: $white;
                            font-weight: 500;
                            margin-right: 10px;
                        }
                    }
                    a{
                        color: $primary;
                        text-decoration: underline;
                    }
                    ol{
                        margin-left: 1.7rem;
                        padding: 0px;
                        margin-top: 10px;
                    }
                    &:last-child{
                        margin-bottom: 0px;  
                    }
                }
            }
            ul{
                li{
                    &::before{
                        display: none !important;
                    }
                }
            }
        }
    }
    &::after{
        content: '';
        bottom: 10%;
        background-size: 65%;
    }
    &::before{
        background-size: 65%;
    }
}
@media (max-width:960px){
    .tc-block{
        .terms-and-conditions-section{
            width: 90%;
            padding: 1rem 20px;
            .table-responsive{
                width: 100%;
                overflow: scroll;
                .content-table{
                    width: 900px;
                }
            }
            ol.sub-number-ol li a{
                word-break: break-all;
            }
        }
    }
}

@media (max-width:768px){
    .tc-block .terms-and-conditions-section{
        h1{
            font-size: 34px;
        }
        p{
            font-size: 18px;
        }
    }
}