@import '../../assets/scss/variables';

.affiliates{
    &-dashboard{
        width: 90%;
        max-width: 1416px;
        margin: 0 auto;
        color: $white;
        padding-bottom: 10rem;
        h1{
            margin: 0px 0px 15px;
            font-size: 52px;
            font-weight: 500;
            line-height: 160%;
        }
        p{
            font-size: 26px;
            font-weight: 400;
            line-height: 160%;
            color: #CED2DD;
            margin: 0px;
        }
    }
    &-datatable-wrapper{
        margin-top: 5.625rem;
        border-radius: 26px;
        background: $secondary-bg;
        padding: 80px 100px;
        .sales-datatable{
            .p-datatable{
                &-header{
                    background: $secondary-bg;
                    border: none;
                    padding: 0rem 0rem 2.9rem;
                    color: $white;
                    h2{
                        margin: 0px;
                        font-size: 26px;
                        font-weight: 500;
                    }
                    .filter-block{
                        .filter-buttons{
                            padding: 9px 18px;
                            background: $accent;
                            border: 1px solid $accent;
                            .p-button-label{
                                color: $white;
                                font-size: 18px;
                                font-weight: 500;
                            }
                            &:hover{
                                background: $secondary-bg;
                                transform: scale(1);
                            }
                            &.reset-button{
                                border-color: #FF4949;
                                background: transparent;
                                .p-button-label{
                                    color: #FF4949;
                                }
                                &:hover{
                                    background: $primary;
                                    .p-button-label{
                                        color: $white;
                                    }
                                }
                            }
                            &:focus{
                                outline: 0 none;
                                box-shadow: none;
                            }
                        }
                        .purchased-date{
                            .p-inputtext{
                                border-radius: 0rem 2rem 2rem 0rem;
                                background: #493A5D;
                                border: none;
                                color: $white;
                                padding-left: 0px;
                                font-size: 18px;
                                font-weight: 500;
                                box-shadow: none;
                                &:focus{
                                    box-shadow: none;
                                }
                                &::placeholder{
                                    color: $white;
                                }
                            }
                            .p-datepicker-trigger {
                                border-radius: 2rem 0rem 0rem 2rem;
                                background: #493A5D;
                                border: none;
                                &:focus{
                                    box-shadow: none;
                                }
                            }
                        }
                        .status-dropdown{
                            border-radius: 2rem;
                            background: #493A5D;
                            border: none;
                            width: 200px;
                            .p-dropdown-label{
                                color: $white;
                                font-size: 18px;
                                font-weight: 500;
                                padding: 10px 18.99px;
                                &::placeholder{
                                    color: $white;
                                }
                            }
                            &:focus{
                                box-shadow: none;
                            }
                           .p-dropdown-trigger{
                                svg{
                                    color: $white;  
                                }
                           }
                           &.p-focus{
                                box-shadow: none;
                                outline: 0 none;
                            }
                            .p-placeholder, .p-multiselect-trigger{
                                color: $white;
                            }
                        }
                    }
                }
                &-wrapper{
                    border-radius: 14px;
                    border: 1px solid #332447;
                }
                &-table{
                    .p-datatable-thead > tr > th{
                        background: #1F122F;
                        padding: 21px 22px;
                        border: none;
                        .p-column-header-content{
                            .p-column-title{
                                font-size: 13.405px;
                                font-weight: 500;
                                line-height: 140%;
                                color: $white;
                            }
                            .p-icon{
                                color: #909090;
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                    .p-datatable-tbody > tr {
                        background: #0E0020;
                        td{
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 160%;
                            color: $white;
                            border-bottom: 1px solid #332447;
                            padding: 28px 22px;
                            .completed{
                                color: #00E469;
                            }
                            .failed{
                                color: #FF4949;
                            }
                            .pending{
                                color:#FFA500
                            }
                        }
                        &:last-child{
                            td{
                                border: none;
                            }
                        }
                    }
                }
            }
        }
        .p-datatable-footer{
            border: none;
            background: none;
            .pagination-section{
                display: flex;
                gap: 18px;
                align-items: center;
                button{
                    font-size: 16px;
                    font-weight: 400;
                    background: #493A5D;
                    border: none;
                    width: auto;
                    padding: 8px 12px;
                    .p-button-icon{
                        font-size: 14px;
                    }
                    &.p-disabled{
                        background: #2a1b3e;
                        opacity: 0.4;
                    }
                    &:hover{
                        background: $accent;
                    }
                }
                p{
                    font-size: 18px;
                }
            }
        }
    }
}
.btn-disable {
    opacity: 0.5;
    pointer-events: none;
}

.affiliate-link-container{
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    justify-content: center;
    .affiliate-link-label{
        font-size: 18px;
        font-weight: 400;
        line-height: 160%;
    }
    .link-block{
        border-radius: 8px;
        border: 1px solid rgba(226, 227, 238, 0.13);
        background: rgba(22, 0, 51, 0.10);
        padding: 16px 22px;
        display: flex;
        align-items: center;
        gap: 5px;
        .affiliate-link{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 665px;
            display: inline-block;
        }
        .pi{
            font-size: 24px;
            margin-left: 1.5rem;
            cursor: pointer;
            &:hover{
                color: $primary;
            }
        }
    }
}

// Responsive CSS

@media (max-width:991px){
    .affiliates-dashboard{
        h1{
            font-size: 34px;
        }
        p{
            font-size: 18px;
        }
    }
    .affiliates-datatable-wrapper{
        padding: 30px;
        .sales-datatable .p-datatable-header h2{
            margin: 0px 0px 15px;
        }
    }
    .affiliate-link-container{
        flex-direction: column;
        gap: 1rem;
        .link-block{
            gap: 15px;
            flex-direction: column;
            width: 96%;
            .affiliate-link{
                white-space: normal;
                max-width: 100%;
                word-wrap: break-word;
            }
        }
    }
}


@media (max-width:1200px){
    .affiliates-datatable-wrapper{
        .p-datatable-table{
            min-width: 1200px;
        }
    }
}

@media (min-width:992px) and (max-width:1199px){
    .affiliate-link-container{
        flex-direction: column;
    }
}