@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Global CSS **** //

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: $black;
    }
    &::-webkit-scrollbar-thumb {
        background: $primary;
        padding: 2px;
    }
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.p-component{
    font-family: $font-base;
}

.main-section{
    padding: 0px;
    background-color: $main-bg;
    .header-menu-section{
        padding: 50px 75px;
        position: relative;
        z-index: 1;
        .p-menubar{
            background: none;
            border: none;
            padding: 0rem;
            .p-menubar-start{
                margin-right: 35px;
            }
        }
        .header-right{
            .language-dropdown{
                background: transparent;
                border: none;
                .p-dropdown-label{
                    padding: 0px;
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: -0.9px;
                    color: $white;
                }
                &.p-focus{
                    box-shadow: none;
                    border: none;
                }
                .p-dropdown-trigger{
                    color: $white;
                }
            }
            .login-button{
                background: $accent;
                border-radius: 8px;
                padding: 18px 38px;
                border: none;
                .p-button-label{
                    font-size: 22px;
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    line-height: 24px;
                }
                &:hover{
                    background: $primary;
                }
            }
            .my-account-buttons{
                width: 60px;
                height: 60px;
                background: rgba(239, 240, 245, 0.19);
                border-radius: 2rem;
                border: none;
                margin: 0rem 0.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                &.user-button{
                    background: $white;
                }
                &:focus{
                    box-shadow: none;
                }
            }
        }
    }
}
.p-dropdown-panel{
    margin-top: 1.5rem;
    border-radius: 15px;
    border: 1.687px solid rgba(211, 221, 233, 0.18);
    background: #332447;
    .p-dropdown-items-wrapper{
        border-radius: 10px;
        .p-dropdown-items{
            margin: 0px;
            padding: 10px;
            .p-dropdown-item{
                background: #332447;
                border: 1px solid #332447;
                font-size: 15px;
                padding: 0.65rem 1rem;
                color: $white;
                font-size: 20px;
                font-weight: 500;
                line-height: 160%;
                &.p-highlight{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                }
                &:hover{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                }
            }
        }
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #574C65;
            padding: 2px;
            border-radius: 6px;
        }
    }
}
.p-menu-overlay{
    margin-top: 1.5rem;
    border-radius: 15px;
    border: 1.687px solid rgba(211, 221, 233, 0.18);
    background: #332447;
    padding: 0rem;
    width: auto;
    .p-menu-list{
        padding: 1rem;
        .p-menuitem{
            .p-menuitem-content{
                .p-menuitem-link{
                    padding: 0px 14px;
                    .p-menuitem-text{
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 160%;
                        color: $white;
                        font-family: $font-base;
                    }
                    .p-image{
                        margin-right: 12px;
                        line-height: 0;
                        img{
                            width: 26px;
                        }
                    }
                }
                &:hover{
                    border: none;
                    background: none;
                }
            }
            &.p-focus{
                .p-menuitem-content{
                    border: none;
                    background: none;
                } 
            }
        }
    }
}
.logout-dialog{
    width: 90%;
    max-width: 525px;
    padding: 3.25rem;
    border-radius: 28.204px;
    background: #160033;
    .p-dialog-header{
        padding: 0rem;
        position: absolute;
        background: none;
        right: 52px;
        top: 68px;
        border-radius: 20px;
        .p-dialog-header-icons{
            padding: 4px;
            border-radius: 20px;
            &:hover{
                background: $white;
            }
            .p-link{
                svg.p-icon{
                    width: 30px;
                    height: 30px;
                }
               &:hover{
                background: none;
               }
            }
        }
    }
    .p-dialog-content{
        padding: 0rem;
        background: transparent;
        color: $white;
        h2{
            margin: 0px;
            font-size: 28px;
            font-weight: 500;
            line-height: 150%;
        }
        .logout-icon{
            border-radius: 12px;
            background: #44345A;
            width: 65px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            .p-image{
                line-height: 0px;
            }
        }
        p{
            margin: 0px;
            font-size: 28px;
            font-weight: 500;
            line-height: 150%;
            padding: 2.245rem 0rem;
        }
        .logout-buttons{
            border-radius: 11px;
            background: #6A459A;
            border: 2px solid #6B4C93;
            width: 100%;
            .p-button-label{
                font-size: 21px;
                font-weight: 600;
                line-height: 32.639px;
                color: $white;
            }
            &.cancel{
                background: #160033;
                &:hover{
                    background: $primary;
                    border: 2px solid $primary;
                    .p-button-label{
                        color: $white;
                    }
                }
            }
            &:focus{
                box-shadow: none;
            }
            &:hover{
                background: $white;
                border: 2px solid $white;
                .p-button-label{
                    color: #6A459A;
                }
            }
        }
    }
}
.button{
    border-radius: 11px;
    padding: 20px;
    font-size: 24px;
    &-primary{
        background-color: $btn-bg;
        border-color: $btn-bg;
    }
    &-secondary{
        background-color: $main-bg;
        border: 2px solid $btn-bg;
    }
}
.icon-blk{
    background-color: $icon-bg;
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    border-radius: 35px;
}
.footer-section{
    border-top: 1px solid #3F3250;
    background: #0E0020;
    padding: 84px 0px 58px 0px;
    .footer-info{
        width: 90%;
        max-width: 1340px;
        margin: 0 auto;
        h3{
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.73);
            margin: 0rem 0rem 1.3125rem;
        }
        p{
            color: $white;
            a{
                color: $white;
                &:hover{
                    color: $primary;
                }
            }
        }
        .footer-button {
            cursor: pointer; 
            color: $white; 
            transition: color 0.3s ease; 
            
            &:hover {
              color: $primary;
            }
        }
        .p-image{
            line-height: 0px;
        }
    }
    .copyrights-section{
        width: 90%;
        max-width: 1716px;
        margin: 4rem auto 0rem;
        border-top: 1px solid #3F3250;
        padding-top: 3.5rem;
        p{
            font-size: 18px;
            font-weight: 300;
            color: $white;
            line-height: 145%;
            margin: 0px;
            a{
                text-decoration-line: underline;
                color: $white;
                &:hover{
                    color: $primary;
                }
            }
        }
        .social-icons{
            a{
                position: relative;
                transition: all 0.2s ease-in-out;
                top: 0px;
                &:hover{
                    opacity: 0.7;
                    top: -6px;
                }
            }
        }
    }
}

.hide-on-mobile{
    display: block;
}
.show-on-mobile{
    display: none;
}

.p-toast{
    opacity: 1;
    z-index: 9999 !important;
    max-width: 26rem;
    .p-toast-message{
        &.p-toast-message-success{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #2BA079;
                border-radius: 2px;
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #ff5757;
                border-radius: 2px;
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #cc8925;
                border-radius: 2px;
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #5774cd;
                border-radius: 2px;
            }
            .p-toast-message-icon{
                background: #5774cd !important;
            }
        }
        .p-toast-message-content{
            padding: 12px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: 500;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
            }
        }
    }
}

// Responsive CSS

@media (max-width:991px){
    .main-section{
        .header-menu-section{
            padding: 20px 20px;
            .p-menubar {
                position: relative;
                .p-menubar-root-list{
                    gap: 10px !important;
                    .p-menuitem-text{
                        font-size: 18px !important; 
                    }
                    .p-menuitem .p-menuitem-content{
                        padding: 8px 10px;
                    }
                }
                .p-menubar-start{
                    margin-right: 15px;
                }
            }
            .header-left{
                .logo-img{
                    img{
                        height: 65px;
                    }
                }
            }
            .header-right{
                margin-right: 0rem;
                .login-button{
                    padding: 12px 28px;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .my-account-buttons{
                    margin: 0rem 1rem 0rem 0rem;
                    width: 50px;
                    height: 50px;
                }
                .pi-chevron-down{
                    display: none;
                }
            }
            .p-menubar-button{
                border-radius: 8px;
                background: #251B32;
                width: 48px;
                height: 48px;
                position: absolute;
                right: 0;
                svg{
                   display: none;
                }
                &::before{
                    content: "\e91d";
                    font-family: primeicons;
                    font-size: 24px;
                    color: $white;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .p-menubar-mobile-active{
                .p-menubar-button{
                    &::before{
                        content: "\e90b";
                    }
                }
            }
        }
    } 
    .footer-section{
        text-align: center;
        .footer-info{
            h3{
                margin-top: 1.2rem;
            }
            .payment-accept{
                justify-content: center;
            }
        }
        .copyrights-section{
            .mobile-flex{
                flex-direction: column !important;
                .social-icons{
                    justify-content: center;
                    margin-top: 2rem;
                }
            }
        }
    }
    .p-dropdown-panel.language-panel {
        margin-left: 0rem; 
    }
    .hide-on-mobile{
        display: none;
    }
    .show-on-mobile{
        display: block;
    }
}

@media (min-width:769px) and (max-width:991px){
    .footer-section .footer-info{
        .grid{
            .order-1{
                order:1;
                margin-bottom: 2rem;
            }
            .order-2{
                order:3;
            }
            .order-3{
                order:4;
            }
            .order-4{
                order:2;
            }
        }
    }
}

@media (min-width:992px) and (max-width:1199px){
    .main-section{
        .header-menu-section{
            padding: 30px 25px;
        }
    }
}
@media (max-width:991px){
    .main-section{
        .header-menu-section{
            .p-menubar {
                .p-menubar-button, .p-menubar-root-list{
                    display: none;
                }
            }
        }
    }
}
@media (max-width:430px){
    .main-section{
        .header-menu-section{
            .header-right .login-button {
                padding: 12px 15px;
            }
            .p-menubar .p-menubar-start{
                margin-right: 0px;
            }
        }
    }
}