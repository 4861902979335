@import '../../assets/scss/variables';

.signup-section {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    color: $white;
    padding-bottom: 10rem;

    h1 {
        margin: 0px;
        font-size: 50px;
        font-weight: 500;
        line-height: 160%;
    }

    p {
        font-size: 22px;
        font-weight: 400;
        line-height: 160%;
        color: #CED2DD;
        margin: 0px;
    }

    .error-message {
        color: $primary;
        font-size: 13px;
        margin: 5px 2px;
    }

    .signup-wrapper {
        text-align: left;
        border-radius: 9px;
        background: $purple-dark;
        padding: 36px;
        margin-top: 4.3rem;

        .form-block {
            .input-field {
                margin-bottom: 0.875rem;

                label {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 160%;
                }

                .p-inputtext {
                    border-radius: 8.892px;
                    border: 1px solid rgba(226, 227, 238, 0.13);
                    background: rgba(22, 0, 51, 0.10);
                    padding: 14.82px 23.711px 14.82px 20.747px;
                    color: $white;
                    font-family: $font-base;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 160%;
                    width: 100%;

                    .p-input-icon {
                        svg {
                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }

                    &::placeholder {
                        color: #b7b7b7;
                    }
                }

                .p-icon-field {
                    width: 100%;
                }

                .p-chips-multiple-container {
                    height: auto;
                    max-height: 65px;
                    overflow-y: scroll;

                    .p-chips-token {
                        padding: 0.2rem 0.75rem;
                        margin-bottom: 5px;
                        background: $white;

                        .p-chips-token-label {
                            font-size: 14px;
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                        border-radius: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background: $purple-dark;
                        border-radius: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $accent;
                        padding: 2px;
                        border-radius: 5px;
                    }

                    input {
                        color: $white;

                        &::placeholder {
                            color: #b7b7b7;
                        }
                    }
                }

            }

            .signup-button {
                background: $accent;
                border-radius: 12px;
                padding: 14px 40px;
                border: none;
                margin-top: 0.5rem;

                .p-button-label {
                    font-size: 21px;
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    line-height: 35.567px;
                }

                &:hover {
                    background: $primary;
                }
            }

            .link-p {
                font-size: 22px;
                font-weight: 400;
                line-height: 160%;
                color: #DADDE5;
                margin-top: 2rem;
                text-align: center;

                a {
                    font-weight: 500;
                    color: $white;
                    margin-left: 5px;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .forgot-pass-p {
                color: #DADDE5;
                font-size: 22px;
                font-weight: 400;
                line-height: 160%;
                margin: 2rem 0rem 0rem;

                a {
                    color: #DADDE5;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .social-button {
                flex-direction: row-reverse;
                padding: 15px 16px 15px 20px;
                border-radius: 8px;
                background: #E4E9F1;
                border: none;
                width: 100%;
                text-align: left;

                .p-button-label {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 160%;
                    color: #181B27;
                    margin-left: 10px;
                }

                .p-image {
                    line-height: 0;

                    img {
                        height: 24px;
                    }
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .no-spinner::-webkit-outer-spin-button,
    .no-spinner::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .p-checkbox {
        position: relative;
        top: 3px;

        .p-checkbox-box {
            background: $purple-dark;
            border-radius: 2px;
            width: 25px;
            height: 25px;
            min-width: 25px;

            &:hover {
                border: 2px solid $accent;
            }

            .p-checkbox-icon.p-icon {
                width: 15px;
                height: 15px;
            }
        }

        &.p-highlight {
            .p-checkbox-box {
                border: 2px solid $accent;
            }
        }
    }
    label {
        color: #fff;
        font-size: 20.747px;
        font-weight: 400;
        line-height: 160%;
        a{
            color: $primary;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

.custom-phoneno-field {
    .react-tel-input {
        font-family: $font-base;

        .form-control {
            border-radius: 8px;
            border: 1px solid rgba(226, 227, 238, 0.13);
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            width: 100%;
            font-family: $font-base;
        }

        .flag-dropdown {
            border-radius: 8px 0 0 8px;
            background-color: transparent;
            border: 1px solid rgba(226, 227, 238, 0.13);

            .selected-flag {
                border-radius: 8px 0 0 8px;
                width: 51px;

                &:focus {
                    background-color: transparent;
                }

                &:hover {
                    background-color: transparent;
                }

                .arrow {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $white;
                    left: 25px;

                    &.up {
                        border-top: none;
                        border-bottom: 5px solid $white;
                    }
                }
            }

            &.open {
                background: none;
                z-index: 5;

                .selected-flag {
                    background: none;
                }
            }

            .country-list {
                font-family: $font-base;
                border-radius: 10px;
                width: 375px;
                padding: 0px 5px;
                max-height: 225px;
                margin: 15px 0 10px -1px;
                border: 1.687px solid rgba(211, 221, 233, 0.18);
                background: #332447;

                &::-webkit-scrollbar {
                    width: 3px;
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-track {
                    background: #332447;
                    border-radius: 5px;
                    margin: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #504361;
                    border-radius: 5px;
                    padding: 2px;
                }

                .country {
                    padding: 10px 10px;
                    margin: 0px 10px;
                    border: 1px solid #332447;

                    span {
                        color: $white;
                    }

                    &.highlight {
                        border: 1px solid #504361;
                        background-color: #3E2E54;
                        border-radius: 8px;
                    }

                    &:hover {
                        border: 1px solid #504361;
                        background-color: #3E2E54;
                        border-radius: 8px;
                    }
                }

                .flag {
                    top: 6px;
                }

                .search {
                    background: #332447;

                    .search-emoji {
                        display: none;
                    }

                    .search-box {
                        border-radius: 8px;
                        font-size: 16px;
                        font-weight: 400;
                        padding: 11px 15px 9px 35px;
                        margin-left: 0px;
                        width: 96%;
                        border: 1.687px solid rgba(211, 221, 233, 0.18);
                        background: #332447;
                        padding-left: 38px !important;
                        color: $white;
                    }

                    &::after {
                        content: "\e908";
                        position: absolute;
                        left: 20px;
                        font-size: 16px;
                        font-family: primeicons;
                        top: 22px;
                        color: $white;
                    }
                }

                .no-entries-message {
                    color: $white;
                }
            }
        }
    }
}

.afid-span {
    position: absolute;
    right: 29px;
    top: 68px;
}

.react-tel-input input {
    padding-left: 75px !important;
}

.p-tooltip-right {
    width: 100%;
    max-width: 300px;

    .p-tooltip-text {
        font-size: 14px;
        line-height: 135%;
        background: $accent;

        p {
            margin: 0px;
        }
    }

    .p-tooltip-arrow {
        border-right-color: $accent;
    }
}

// Responsive CSS

@media (max-width:768px) {
    .signup-section {
        h1 {
            font-size: 34px;
        }

        p {
            font-size: 18px;
        }

        .signup-wrapper {
            padding: 30px 20px;

            .form-block {
                .input-field {
                    .p-inputtext {
                        font-size: 16px;
                    }

                    label {
                        font-size: 18px;
                    }
                }

                .forgot-pass-p,
                .link-p {
                    font-size: 18px;
                }

                .signup-button {
                    padding: 10px 40px;

                    .p-button-label {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}