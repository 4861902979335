
$font-base: "Inter", sans-serif;
// Colors
$primary: #F73859;
$accent: #6A459A;
$black: #161b28;
$white: #ffffff;
$main-bg: #0E0020;
$secondary-bg: #160033;
$btn-bg:#6B4C93;
$icon-bg:#301E4C;
$border-color:#4E4E4E;
$purple-dark:#160033;
